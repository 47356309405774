<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <BCard>
      <b-row class="justify-content-between mb-[24px]">
        <b-col
          cols="auto"
          class="flex items-center"
        >
          <b-button
            variant="primary"
            size="sm"
            class="rounded-lg mr-2 my-auto"
            @click="$router.go(-1)"
          >
            <b-icon-chevron-left />
          </b-button>
          <h4><strong class="text-black text-2xl">Data Pelanggan</strong></h4>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-container>
          <b-row
            no-gutters
            align-h="start"
            class="gap-2"
          >
            <b-col md="auto">
              <b-button
                id="filter-product"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="w-[-webkit-fill-available]"
              >
                <div class="flex items-center justify-between gap-4 xl:gap-8">
                  <div class="flex gap-1">
                    <span>Produk</span>
                    <span
                      v-if="filterProductSelect.length > 0"
                      class="text-secondary"
                    >
                      ({{ filterProductSelect.length }})
                    </span>
                  </div>
                  <b-img src="https://storage.googleapis.com/komerce/assets/icons/arrow-filter.svg" />
                </div>
              </b-button>
              <b-popover
                id="popover-filter-product"
                target="filter-product"
                triggers="focus"
                placement="bottom"
              >
                <div class="input-list my-1">
                  <BInputGroup class="input-group-merge">
                    <BInputGroupPrepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </BInputGroupPrepend>
                    <BFormInput
                      v-model="searchFilterProduct"
                      size="md"
                      placeholder="Cari produk"
                      style="padding: 8px 1rem 8px 0px"
                      @input="inputFilterProducts"
                      @keypress="isAlphabet($event)"
                    />
                  </BInputGroup>
                </div>
                <div
                  v-if="isProductList.length !== 0 && !isEmpetyProduct"
                  class="scroll-list mb-1"
                >
                  <div
                    v-for="(item, index) in filterProduct"
                    :key="index+1"
                  >
                    <b-form-checkbox
                      v-model="checkStatusProduct[item.id]"
                      class="custom-control-primary mb-1"
                      @change="changeProduct(item)"
                    >
                      <span class="text-black">
                        {{ truncateString(item.name, 20) }} ({{ item.total }})
                      </span>
                    </b-form-checkbox>
                  </div>
                </div>
                <div
                  v-else
                  class="mb-1"
                >
                  <span>Tidak ada data untuk ditampilkan</span>
                </div>
              </b-popover>
            </b-col>
            <b-col md="auto">
              <b-button
                id="filter-address"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="w-[-webkit-fill-available]"
              >
                <div class="flex items-center justify-between gap-4 xl:gap-8">
                  <div class="flex gap-1">
                    <span>Lokasi</span>
                    <span
                      v-if="filterAddressSelect.length > 0"
                      class="text-secondary"
                    >
                      ({{ filterAddressSelect.length }})
                    </span>
                  </div>
                  <b-img src="https://storage.googleapis.com/komerce/assets/icons/arrow-filter.svg" />
                </div>
              </b-button>
              <b-popover
                id="popover-filter-address"
                target="filter-address"
                triggers="focus"
                placement="bottom"
              >
                <div class="input-list my-1">
                  <BInputGroup class="input-group-merge">
                    <BInputGroupPrepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </BInputGroupPrepend>
                    <BFormInput
                      v-model="searchFilterAddress"
                      size="md"
                      placeholder="Cari lokasi"
                      style="padding: 8px 1rem 8px 0px"
                      @input="inputFilterAddress"
                      @keypress="isAlphabet($event)"
                    />
                  </BInputGroup>
                </div>
                <div
                  v-if="isAddressList.length !== 0 && !isEmpetyAddress"
                  class="scroll-list mb-1"
                >
                  <div
                    v-for="(item, index) in filterAddress"
                    :key="index+1"
                  >
                    <b-form-checkbox
                      v-model="checkStatusAddress[item.id]"
                      class="custom-control-primary mb-1"
                      @change="changeAddress(item)"
                    >
                      <span class="text-black">
                        {{ truncateString(item.name, 20) }} ({{ item.total }})
                      </span>
                    </b-form-checkbox>
                  </div>
                </div>
                <div
                  v-else
                  class="mb-1"
                >
                  <span>Tidak ada data untuk ditampilkan</span>
                </div>
              </b-popover>
            </b-col>
            <b-col md="auto">
              <div>
                <b-form-select
                  v-model="filter.value"
                  :options="filterOrder"
                  @change="changeTotalOrder"
                />
              </div>
            </b-col>
            <b-col md="auto">
              <b-button
                id="filter-shipping"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="w-[-webkit-fill-available]"
              >
                <div class="flex items-center justify-between gap-4 xl:gap-8">
                  <span>Total Belanja</span>
                  <b-img src="https://storage.googleapis.com/komerce/assets/icons/arrow-filter.svg" />
                </div>
              </b-button>
              <b-popover
                id="popover-filter-shipping"
                target="filter-shipping"
                triggers="focus"
                placement="bottom"
              >
                <div class="mb-[10px]">
                  <b-input-group
                    prepend="Rp"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-form-input
                      id="range-min"
                      v-model="typingInputMinShipping"
                      placeholder="Harga minimum"
                      @input="inputMinTotalShipping"
                      @keypress="isNumber($event)"
                    />
                  </b-input-group>
                </div>
                <div class="mb-[10px]">
                  <b-input-group
                    prepend="Rp"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-form-input
                      id="range-max"
                      v-model="typingInputMaxShipping"
                      placeholder="Harga maksimum"
                      @input="inputMaxTotalShipping"
                      @keypress="isNumber($event)"
                    />
                  </b-input-group>
                </div>
              </b-popover>
            </b-col>
            <b-col md="auto">
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                :locale-data="locale"
                :ranges="ranges"
                :opens="'center'"
                variant="outline-secondary"
                class="filter-range w-[-webkit-fill-available]"
              >
                <template v-slot:input="picker">
                  <div class="flex items-center justify-between gap-4 xl:gap-8">
                    <span class="reportrange-text">Tanggal</span>
                    <b-img src="https://storage.googleapis.com/komerce/assets/icons/arrow-filter.svg" />
                  </div>
                </template>
              </date-range-picker>
            </b-col>
          </b-row>
        </b-container>
      </b-row>
      <BOverlay
        id="infinite-list"
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <!-- <div style="height: calc(100vh - 330px); overflow-y: scroll;"> -->
        <BTable
          id="table-pelanggan"
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-1"
        >

          <template #cell(phone)="data">
            <div class="min-w-[140px] text-[#222]">
              <span class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                  alt="icon whatsapp"
                >
                <span class="ml-[12px]">{{ data.item.phone }}</span>
              </span>
            </div>
          </template>

        </BTable>
        <!-- </div> -->
        <BRow>
          <BCol
            cols="12"
            class="d-flex justify-content-between"
          >
            <div class="d-flex justify-content-center align-items-center gap-[16px]">
              <strong class="text-[#333]"> Total Data Pelanggan </strong>
              <strong class="text-[#F95031]">{{ countTotal(items) || 0 }}</strong>
            </div>

            <b-button
              variant="primary"
              class="rounded-lg w-[150px]"
              @click="sendDataImport"
            >
              Buat
            </b-button>
          </BCol>
        </BRow>
      </BOverlay>
    </BCard>
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last2,
  last7,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isNumber, isAlphabet } from '@/libs/helpers'

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      search: '',
      searchFilterProduct: '',
      searchFilterAddress: '',
      typingTimeoutProduct: null,
      typingTimeoutAddress: null,
      typingInputMinShipping: '',
      typingInputMaxShipping: '',
      isInputMin: '',
      isInputMax: '',
      isEmpetyProduct: false,
      isEmpetyAddress: false,
      debounceTimerShipping: null,
      loading: false,
      isLoading: false,
      selected: null,
      fields: [
        {
          key: 'name',
          label: 'Nama',
          thClass: 'font-bold text-black',
          class: 'align-middle text-black',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'No. HP',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'address',
          label: 'Lokasi',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
      ],
      items: [],
      filterAddress: [],
      filterAddressSelect: [],
      filterProductSelect: [],
      selectedFilterProduct: [],
      checkStatusProduct: {},
      isProductList: 0,
      selectedFilterAddress: [],
      checkStatusAddress: {},
      isAddressList: 0,
      filterProduct: [],
      filterOrder: null,
      filter: {
        value: null,
      },
      filterMin: [],
      filterMax: [],
      //   date ranges picker
      picker: {
        startDate: null,
        endDate: null,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        'Hari Ini': [today, today],
        Kemarin: [last2, last2],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      today,
      last2,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      isNumber,
      isAlphabet,
    }
  },
  watch: {
    dateRange(value) {
      this.getDataImport(value)
    },
  },
  mounted() {
    this.getDataImport()
    this.getDataFilter()
  },
  methods: {
    inputMinTotalShipping(inputVal) {
      if (this.debounceTimerShipping) {
        clearTimeout(this.debounceTimerShipping)
      }
      this.debounceTimerShipping = setTimeout(() => {
        const numericInput = inputVal.replace(/[^\d]/g, '')
        if (numericInput.length >= 4) {
          const formattedInput = this.formatInputMin(numericInput)
          this.typingInputMinShipping = formattedInput
        } else {
          this.typingInputMinShipping = numericInput
        }
        this.isInputMin = inputVal
      }, 1000)
    },
    inputMaxTotalShipping(inputVal) {
      if (this.debounceTimerShipping) {
        clearTimeout(this.debounceTimerShipping)
      }
      this.debounceTimerShipping = setTimeout(() => {
        const numericInput = inputVal.replace(/[^\d]/g, '')
        if (numericInput.length >= 4) {
          const formattedInput = this.formatInputMin(numericInput)
          this.typingInputMaxShipping = formattedInput
        } else {
          this.typingInputMaxShipping = numericInput
        }
        this.isInputMax = inputVal
        if (this.typingInputMinShipping.length > 0 && this.typingInputMaxShipping.length > 0) {
          this.getDataImport()
        }
      }, 1000)
    },
    formatInputMin(inputVal) {
      const formattedInput = inputVal.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      return formattedInput
    },
    countTotal(v) {
      if (v === null || v === undefined) {
        return 0
      } return v.length
    },
    inputFilterProducts() {
      if (this.typingTimeoutProduct) {
        clearTimeout(this.typingTimeoutProduct)
      }
      this.typingTimeoutProduct = setTimeout(() => {
        const filteredProducts = this.filterProduct.filter(item => item.name.toLowerCase().includes(this.searchFilterProduct.toLowerCase()))
        this.isProductList = filteredProducts
      }, 1000)
    },
    inputFilterAddress() {
      if (this.typingTimeoutAddress) {
        clearTimeout(this.typingTimeoutAddress)
      }
      this.typingTimeoutAddress = setTimeout(() => {
        const filteredAddress = this.filterAddress.filter(item => item.name.toLowerCase().includes(this.searchFilterAddress.toLowerCase()))
        this.isAddressList = filteredAddress
      }, 1000)
    },
    truncateString(str, maxLength) {
      if (str.length <= maxLength) {
        return str
      }
      return `${str.slice(0, maxLength)}...`
    },
    changeAddress(dataAddress) {
      const findObj = this.filterAddressSelect.findIndex(item => item.id === dataAddress.id)

      if (findObj !== -1) {
        this.filterAddressSelect.splice(findObj, 1)
      } else {
        this.filterAddressSelect.push(dataAddress)
      }

      const selectedAddress = this.filterAddressSelect.map(item => item.id)
      const joinedSelectedAddress = selectedAddress.length > 1 ? selectedAddress.join(',') : selectedAddress[0]
      this.selectedFilterAddress = joinedSelectedAddress
      this.getDataImport()
    },
    changeTotalOrder(dataOrder) {
      const selectedRangeObj = this.filterTotalOrder.find(item => item.formattedRange === dataOrder)
      if (selectedRangeObj) {
        const { totalMin, totalMax } = selectedRangeObj
        this.filterMin = `${totalMin}`
        this.filterMax = `${totalMax}`
      }
      if (dataOrder === null) {
        this.filterMin = ''
        this.filterMax = ''
      }
      this.getDataImport()
    },
    changeProduct(dataProduct) {
      const findObj = this.filterProductSelect.findIndex(item => item.id === dataProduct.id)

      if (findObj !== -1) {
        this.filterProductSelect.splice(findObj, 1)
      } else {
        this.filterProductSelect.push(dataProduct)
      }

      const selectedProducts = this.filterProductSelect.map(item => item.id)
      const joinedSelectedProducts = selectedProducts.length > 1 ? selectedProducts.join(',') : selectedProducts[0]
      this.selectedFilterProduct = joinedSelectedProducts
      this.getDataImport()
    },
    async getDataImport() {
      this.isLoading = true
      const params = {
        product: this.selectedFilterProduct,
        address: this.selectedFilterAddress,
        order_min: this.filterMin,
        order_max: this.filterMax,
        order_amount_min: this.isInputMin,
        order_amount_max: this.isInputMax,
        order_date_min: this.dateRange.startDate,
        order_date_max: this.dateRange.endDate,
      }
      await this.$http_new.get('/komship/api/v1/contact/order/list', { params })
        .then(res => {
          const { data } = res.data.data
          this.items = data
          if (data === null || data === undefined) {
            this.countTotal()
          }
          this.isLoading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    async getDataFilter() {
      this.isLoading = true
      await this.$http_new.get('/komship/api/v1/contact/order/filter')
        .then(res => {
          const { data } = res.data
          this.filterProduct = data.product
          this.filterAddress = data.address
          if (data.address === null) {
            this.isEmpetyAddress = true
          }
          if (data.product === null) {
            this.isEmpetyProduct = true
          }
          this.filterTotalOrder = data.total_order.map(item => {
            const totalMin = item.min
            const totalMax = item.max
            const formattedRange = `${totalMin} - ${totalMax}`

            return {
              ...item,
              totalMin,
              totalMax,
              formattedRange,
            }
          })
          const xxx = this.filterTotalOrder.map(x => x.formattedRange)
          const defaultOptions = [{ value: null, text: 'Total Order' }]
          if (xxx) {
            this.filterOrder = defaultOptions.concat(xxx)
          } else {
            this.filterOrder = defaultOptions
          }
          this.isLoading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    async sendDataImport() {
      const dataSend = this.items.map(item => ({
        name: item.name,
        phone: `0${item.phone}`,
        address: item.address,
        var1: item.var_1,
        var2: item.var_2,
        var3: item.var_3,
      }))
      const params = {
        contact: dataSend,
      }
      await this.$http_new.post('/komchat-api/api/v1/contact/komship/import', params)
        .then(res => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Data berhasil ditambahkan!',
                variant: 'success',
              },
            },
            2000,
          )
          this.$router.go(-1)
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Data gagal ditambahkan!',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
  },
}
</script>

<style scoped>
.scroll-list {
  max-height: 200px;
  overflow-y: scroll;
}
.reportrange-text {
    border: none !important;
}
.list-item-total-produk:hover {
  background-color: bisque;
}
</style>
